<template>
  <div>
    <div class="section-header">
      <h3 v-if="sectionTitle" class="section-title">
        {{ sectionTitle }}
      </h3>

      <a
        v-if="editEnabled"
        class="section-edit-link"
        aria-label="Edit link"
        @click="toggleEdit()"
      >
        Edit
      </a>
    </div>
    <div class="card">
      <div class="card-details">
        <template v-if="hasSchemaFields">
          <edit-details
            v-if="editMode"
            :company-details-item="companyDetailsItem"
            :schema-key="schemaKey"
            @cancel-edit-mode="CancelEditMode"
          />
          <view-details
            v-else
            :key="`${schemaKey}-${viewKey}`"
            :schema="companyDetailsItem?.schema"
            :company-details-item="companyDetailsItem"
          />
        </template>

        <template v-else>
          <p>No Data</p>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: "CompanyDetailSection",

  components: {
    EditDetails: () => import('@/components/StagelineV2/companyOverview/companyDetails/EditDetails.vue'),
    ViewDetails: () => import('@/components/StagelineV2/companyOverview/companyDetails/ViewDetails.vue'),
  },

  props: {
    sectionTitle: {
      type: String,
      default: '',
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
    schemaKey: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      editMode: false,
      companyDetailsItem: {},
      companyDetails: {},
      schema: [],
      viewKey: 0,
    }
  },

  computed: {
    ...mapGetters('stagelineCompanyOverview', [
      'company',
      'peopleSchema',
      'registrationSchema',
      'companySchema',
    ]),
    editEnabled() {
      return !this.readOnly
    },
    hasSchemaFields() {
      return this.schema.length > 0
    },
  },

  watch: {
    company: {
      handler() {
        this.updateSchema(this.schemaKey)
      },
      deep: true,
    },
  },

  mounted() {
    this.schema = this.setSchema(this.schemaKey)

    this.companyDetailsItem =  {
      type: 'custom-source-item',
      schema: this.schema || [],
      configs: {
        prefill_company_details: true,
      },
      company: {
        id: this.company.id,
        details: this.company.details || {},
      },
    }
  },

  methods: {
    toggleEdit() {
      this.editMode = !this.editMode
    },
    CancelEditMode() {
      this.editMode = false
    },
    setSchema(key) {
      switch (key) {
        case 'companySchema':
          return this.companySchema
        case 'registrationSchema':
          return this.registrationSchema
        case 'peopleSchema':
          return this.peopleSchema
        default:
          return []
      }
    },
    updateSchema() {
      this.schema = this.setSchema(this.schemaKey)
      this.companyDetailsItem.company.details = this.company?.details
      this.companyDetailsItem.schema = this.schema
      this.viewKey++
    },
  },
}
</script>

<style lang="scss" scoped>
.section-title {
  margin-top: 0;
}
.section-header {
  display: flex;
  align-items: center;
}
.section-edit-link {
  margin-left: auto;
  color: #007bff !important;
  &:hover {
    cursor: pointer;
    color: #0056b3;
    text-decoration: underline !important;
  }
}

.card-details {
  padding: 0.625em;
}
</style>
