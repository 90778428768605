var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "section-header" }, [
      _vm.sectionTitle
        ? _c("h3", { staticClass: "section-title" }, [
            _vm._v("\n      " + _vm._s(_vm.sectionTitle) + "\n    "),
          ])
        : _vm._e(),
      _vm.editEnabled
        ? _c(
            "a",
            {
              staticClass: "section-edit-link",
              attrs: { "aria-label": "Edit link" },
              on: {
                click: function ($event) {
                  return _vm.toggleEdit()
                },
              },
            },
            [_vm._v("\n      Edit\n    ")]
          )
        : _vm._e(),
    ]),
    _c("div", { staticClass: "card" }, [
      _c(
        "div",
        { staticClass: "card-details" },
        [
          _vm.hasSchemaFields
            ? [
                _vm.editMode
                  ? _c("edit-details", {
                      attrs: {
                        "company-details-item": _vm.companyDetailsItem,
                        "schema-key": _vm.schemaKey,
                      },
                      on: { "cancel-edit-mode": _vm.CancelEditMode },
                    })
                  : _c("view-details", {
                      key: `${_vm.schemaKey}-${_vm.viewKey}`,
                      attrs: {
                        schema: _vm.companyDetailsItem?.schema,
                        "company-details-item": _vm.companyDetailsItem,
                      },
                    }),
              ]
            : [_c("p", [_vm._v("No Data")])],
        ],
        2
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }